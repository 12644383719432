<template>
	<el-dialog
		class="adjust_class_dialog"
		title="调整班级"
		:visible.sync="dialogVisible"
		width="width"
		:before-close="dialogBeforeClose">
		<div>
			<checkboxAll @checked="changeChecked" :group="checkboxGroup" :checkedGroup="checked" valueName="className" labelName="className"></checkboxAll>
		</div>
		<div slot="footer">
			<el-button @click="dialogBeforeClose">取 消</el-button>
			<el-button type="primary" @click="dialogSave">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
import checkboxAll from '@/views/teachingProgram/components/checkboxAll';
export default {
  props: {
    dialogData: Object
  },
  components: {
    checkboxAll,
  },
  data() {
    return {
      dialogVisible: false,
      checkboxGroup: [],
      checked: [],
      checkboxValueName: '',
      changeCheck: null,
    };
  },
  created() {
    this.$api.searchStudentsClass()
      .then(result => {
        let { code, data } = result.data;
        if (code) {
          return;
        }
        this.checkboxGroup = data;
        console.log(result);
      })
      .catch(err => {

      });
  },
  methods: {
    dialogBeforeClose() {
      this.dialogVisible = false;
    },
    dialogSave() {
      let { id, changeCheck } = this;
      this.$emit('dialogDataSave', {
        class: changeCheck,
        id
      });
      this.dialogBeforeClose();
    },
    changeChecked(data) {
      let { checkedData } = data;
      console.log(data);
      this.changeCheck = checkedData;
    }
  },
  watch: {
    dialogData(data) {
      let { id, checked } = data;
      this.dialogVisible = true;
      this.checked = checked;
      this.id = id;
    }
  }
};
</script>

<style lang="scss">
.adjust_class_dialog {
	.el-transfer__buttons {
		display: flex;
		flex-direction: column;
		justify-content: center;
		.el-button {
			margin: 10px 0;
		}
	}
}
</style>
