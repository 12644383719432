<template>
	<div class="course-wrapper">
		<div class="course-wrapper-title">
			<span>周计划模版</span>
		</div>

		<div class="course-wrapper-body">
			<div class="search_main weekly_plan_template">
				<el-input class="search_main_title" v-model="searchTitle" placeholder="模版名称" ></el-input>
				<el-select class="search_main_class" v-model="searchSchool" placeholder="选择学校" v-if="isSchoolMaster" @change="handleSchoolChange">
					<el-option
						v-for="checkSchool in schoolGroup"
						:key="checkSchool.label"
						:label="checkSchool.name"
						:value="checkSchool.label"
					></el-option>
				</el-select>
        <el-select class="search_main_class" v-model="searchGrade" placeholder="选择年级" v-if="isSchoolMaster" @change="handleGradeChange">
					<el-option
						v-for="checkGrade in gradeGroup"
						:key="checkGrade.label"
						:label="checkGrade.name"
						:value="checkGrade.label"
					></el-option>
				</el-select>
        <el-select class="search_main_class" v-model="searchClass" placeholder="选择班级">
					<el-option
						v-for="checkClass in checkboxGroup"
						:key="checkClass.label"
						:label="checkClass.name"
						:value="checkClass.label"
					></el-option>
				</el-select>
				<el-date-picker class="search_main_date" v-model="searchUpdateDate" type="date" placeholder="更新时间"></el-date-picker>
				<el-date-picker class="search_main_date" v-model="searchCreateDate" type="date" placeholder="创建时间" ></el-date-picker>
				<el-button @click="handleButtonSearch" type="primary">查询</el-button>
				<el-button @click="handleButtonReset">重置</el-button>
        <el-button style="float: right" v-if="isAdd" class="search_main_button_plus" @click="searchButtonPlus" type="primary" icon="el-icon-plus">新建模版</el-button>
			</div>
			<el-table class="table_data" :data="tableData" border v-loading="tableLoading">
				<el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
				<el-table-column prop="templateName" label="模版名称"></el-table-column>
				<el-table-column v-if="isEdit" prop="classesName" label="应用班级" width="80">
					<template slot-scope="scope">
						<el-button @click="tableHandleButton('updateGrade', scope)" type="text">应用班级</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间" width="150" align="center"></el-table-column>
				<el-table-column prop="updateTime" label="更新时间" width="160" align="center"></el-table-column>
				<el-table-column prop="userName" label="创建人" width="150" align="center"></el-table-column>
				<el-table-column label="操作" width="140" align="center">
					<template slot-scope="scope">
						<el-button @click="tableHandleButton('update', scope)" v-if="isEdit" type="text">编辑</el-button>
						<span class="table_data_button_divider" v-if="isEdit">|</span>
						<el-button @click="tableHandleButton('delete', scope)" v-if="isDelete" type="text">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
      <div class="course-wrapper-footer">
        <div class="footer-left">
          显示第{{(pagination.current - 1) * pagination.size + 1}}-{{(pagination.current - 1) * pagination.size + tableData.length}}条记录，共{{pagination.total}}条记录
        </div>
        <el-pagination
          class="pagination"
          @size-change="paginationSizeChange"
          @current-change="paginationCurrentChange"
          :layout="pagination.layout"
          :total="pagination.total"
          align="right"
          background
        ></el-pagination>
      </div>
		</div>

		<adjustClassDialog :dialogData="dialogData" @dialogDataSave="dialogDataSave"></adjustClassDialog>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import tool from '@/utils/tool';
import adjustClassDialog from '@/views/teachingProgram/weeklyPlanTemplate/adjustClassDialog';
import { getPermissionButton } from '@/utils/getUrlPermissionButtons';
export default {
  name: 'weeklyPlanTemplate',
  components: { adjustClassDialog },
  data() {
    return {
      searchTitle: '',
      searchSchool: '',
      searchGrade: '',
      searchClass: '',
      searchUpdateDate: '',
      searchCreateDate: '',
      tableData: [],
      dialogData: {},
      // 班级数据
      checkboxGroup: [],
      schoolGroup: [], //学校
      gradeGroup: [],// 年级
      pagination: {
        current: 1,
        size: 10,
        layout: 'total,sizes,prev,pager,next,jumper',
        total: 0
      },
      tableLoading: false, // 表格loading
      pageLoading: null, //页面loading

      isEdit: false, // 编辑权限
      isAdd: false, // 新增权限
      isDelete: false, // 删除权限
      isView: false, // 查看权限
      permissionButtonList: [], // 权限列表

      isSchoolMaster: false, //是否园长
    };
  },
  computed: {
    ...mapGetters(['permissions']),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isEdit = val.indexOf('weeklyPlan:update') != -1;
          this.isAdd = val.indexOf('weeklyPlan:save') != -1;
          this.isDelete = val.indexOf('weeklyPlan:delete') != -1;
          this.isView = val.indexOf('weeklyPlan:view') != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
    this.getSchoolInfo((data) => {
      if(data.flag){
        this.isSchoolMaster = data.flag
        if(data.list.length > 0){
          this.searchSchool = data.list[0].label
          this.getGradeInfo(data.list[0].label,() => {
            if(this.gradeGroup.length > 0){
              this.searchGrade = this.gradeGroup[0].label
              this.getSearchClass(this.gradeGroup[0].label,() => {
                if(this.checkboxGroup.length > 0){
                  this.searchClass = this.checkboxGroup[0].label
                  this.pageLoading = this.$loading({
                    lock: true,
                    fullscreen: true,
                    text: '加载中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                  });
                  this.searchWeekTemplateList();
                }
              })
            }
          })
        }
      } else {
        this.getSearchClass()
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.searchWeekTemplateList();
      }
    })
  },
  methods: {
    getPermissionButton,
    //获取班级list
    getSearchClass(gradeId,callback){
      let functionName = ''
      let param = {}
      if(this.isSchoolMaster){
        functionName = 'getClassInfo'
        param.label = gradeId
      } else {
        functionName = 'searchStudentsClass'
      }
      this.$api[functionName](param).then(result => {
        let { code, data } = result.data;
        if (code) {
          return;
        }
        data.map((item) => {
          item.label = item.label ? item.label : item.classLabel
          item.name = item.name ? item.name : item.className
        })
        this.checkboxGroup = data || [];
        callback && callback()
      }).catch(err => {

      });
    },
    //根据学校获取年级list
    getGradeInfo(schoolId,callback){
      let param = { label: schoolId }
      this.$api.getGradeInfo(param).then(result => {
        let { code, data } = result.data;
        if (code) {
          return;
        }
        this.gradeGroup = data || [];
        callback && callback()
      }).catch(err => {

      });
    },
    //获取登录人是否为园长以及为园长是的学校list
    getSchoolInfo(callback){
      this.$api.getSchoolInfo({}).then(result => {
        let { code, data } = result.data;
        if (code) {
          return;
        }
        let resultList = []
        console.log(data.masterSchoolList)
        data.masterSchoolList.map((item) => {
          let schoolInfo = {}
          for(let info in item){
            schoolInfo.label = info
            schoolInfo.name = item[info]
          }
          resultList.push(schoolInfo)
        })
        this.schoolGroup = resultList;
        console.log(resultList)
        callback && callback({ flag: data.isSchoolMaster == '1' ? true : false, list: resultList })
      }).catch(err => {

      });
    },
    //学校变化事件
    handleSchoolChange(val){
      this.searchSchool = val
      this.searchGrade = ''
      this.searchClass = ''
      this.checkboxGroup = []
      this.getGradeInfo(val)
    },
    //年级变化事件
    handleGradeChange(val){
      this.searchGrade = val
      this.searchClass = ''
      this.checkboxGroup = []
      this.getSearchClass(val)
    },
    searchButtonPlus() {
      this.$router.push({
        path: '/TeachingPlanSetting/insert'
      });
    },
    dialogDataSave(data) {
      let { id, class:classes } = data;
      if (classes) {
        this.$api.updateTemplateClass({
          id,
          classes
        })
          .then(result => {
            let { code, data } = result.data;
            if (code) {
              return this.$message.error(data);
            }
            this.$message.success(data);
            this.searchWeekTemplateList();
          })
          .catch(err => {

          });
      }

      console.log(data);
    },
    // 获取周计划模版列表
    searchWeekTemplateList() {
      let { current, size } = this.pagination;
      let params = {
        current,
        size
      };
      if (this.searchTitle) {
        params.templateName = this.searchTitle;
      }
      if (this.searchClass) {
        params.classesLabel = this.searchClass;
      }
      if (this.searchUpdateDate) {
        let formatTime = tool.formatTime(this.searchUpdateDate);
        params.updateStartTime = formatTime + ' 00:00:00';
        params.updateEndTime = formatTime + ' 23:59:59';
      }
      if (this.searchCreateDate) {
        let formatTime = tool.formatTime(this.searchCreateDate);
        params.createStartTime = formatTime + ' 00:00:00';
        params.createEndTime = formatTime + ' 23:59:59';
      }
      this.$api.searchWeekTemplateList(params)
        .then(result => {
          this.pageLoading.close()
          let { code, data } = result.data;
          if (code) {
            return;
          }
          let { current, size, total, records } = data;
          this.pagination.current = current;
          this.pagination.size = size;
          this.pagination.total = total;
          this.tableData = records;

        })
        .catch(error => {
          this.pageLoading.close()
        });
    },
    // 表格按钮
    tableHandleButton(handleName, scope) {
      console.log(scope);
      switch (handleName) {
        case 'updateGrade': {
          let { classesName, id } = scope.row;
          classesName = classesName ? classesName.split(',') : [];
          this.dialogData = {
            checked: classesName,
            id
          };
          break;
        }
        case 'update': {
          let { id } = scope.row;
          this.$router.push('/TeachingPlanSetting/' + id);
          break;
        }
        case 'delete': {
          let self = this;
          this.confirm({
            success() {
              self.$api.deleteWeekTemplate(scope.row.id)
                .then(result => {
                  let { code, data, msg } = result.data;
                  if (code) {
                    self.$message.warning(msg);
                    return;
                  }
                  self.pageLoading = self.$loading({
                    lock: true,
                    fullscreen: true,
                    text: '加载中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                  });
                  self.searchWeekTemplateList();
                  self.$message.success(data);
                })
                .catch(err => {
                  console.log(err);
                });
            },
            fail() {

            }
          });
        }
      }
    },
    /**
     * 每页条数上限改变时
     */
    paginationSizeChange(value) {
      this.pagination.size = value;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.searchWeekTemplateList();
    },
    /**
     * 页数改变时
     */
    paginationCurrentChange(value) {
      this.pagination.current = value;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.searchWeekTemplateList();
    },
    /**
		 * 查询按钮
		 */
    handleButtonSearch() {
      if(this.isSchoolMaster && this.searchClass === ''){
        this.$message.warning("请选择班级");
        return
      }
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.searchWeekTemplateList();
    },
    /**
		 * 重置按钮
		 */
    handleButtonReset() {
      this.searchTitle = '';
      this.searchUpdateDate = '';
      this.searchCreateDate = '';
      if(this.isSchoolMaster){
        this.searchSchool = this.schoolGroup[0].label
        this.getGradeInfo(this.schoolGroup[0].label,() => {
          if(this.gradeGroup.length > 0){
            this.searchGrade = this.gradeGroup[0].label
            this.getSearchClass(this.gradeGroup[0].label,() => {
              if(this.checkboxGroup.length > 0){
                this.searchClass = this.checkboxGroup[0].label
                this.pageLoading = this.$loading({
                  lock: true,
                  fullscreen: true,
                  text: '加载中',
                  spinner: 'el-icon-loading',
                  background: 'rgba(0, 0, 0, 0.7)'
                });
                this.searchWeekTemplateList();
              }
            })
          }
        })
      } else {
        this.searchClass = '';
        this.searchSchool = ''
        this.searchGrade = '';
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.searchWeekTemplateList();
      }
    },
    /**
     * confirm 删除确定窗
     * @param {object} data
     */
    confirm(data) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          data.success && data.success(data.data);
        })
        .catch(() => {
          data.fail && data.fail();
        });
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/css/common_c.scss';
.search_main.weekly_plan_template {
	$width: 200px;
  & + & {
    margin-bottom: 20px;
  }
  &.search_main_2 {
    display: flex;
    justify-content: right;
  }
	.search_main_title,
	.search_main_class,
	.search_main_date {
		margin-right: 20px;
		margin-bottom: 20px;
		width: $width;
	}
  .search_main_date {
    width: 150px;
  }

	.search_main_button_plus {
		width: 120px;
	}
}
.table_data {
	.table_data_button_divider {
		display: inline-block;
		padding: 0 10px;
	}
}
.pagination {
	margin-top: 20px;
}
.course-wrapper-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .footer-left {
    color: #7c7c7c;
    font-size: 14px;
  }
}
</style>
