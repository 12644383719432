<template>
  <div class="checkbox_week">
    <el-checkbox
      v-model="checkAll"
      @change="handleCheckAllChange"
      :indeterminate="isIndeterminate"
      >全选</el-checkbox
    >
    <el-checkbox-group v-model="checked" @change="handleCheckedChange">
      <el-checkbox
        v-for="week in defaultCheckbox"
        :label="week[defaultValueName]"
        :key="week[defaultValueName]"
      >{{week[defaultLabelName]}}</el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  props: {
    group: {
      type: Array,
      required: true,
    },
    checkedGroup: Array,
    valueName: {
      type: String,
      required: true,
    },
    labelName: {
      type: String,
      default: 'label',
    },
  },
  data() {
    return {
      defaultCheckbox: [],
      defaultValueName: '',
      defaultLabelName: '',
      isIndeterminate: false,
      checkAll: false,
      checked: [],
      checkCount: 0,
    };
  },
  created() {
  },
  methods: {
    /**
     * 全选
     */
    handleCheckAllChange(value) {
      console.log(this.$data);
      this.checkAll = value
      this.checked = value
        ? this.defaultCheckbox.map(item => item[this.defaultValueName])
        : [];
      this.isIndeterminate = false;
      this.emitCheckData();
    },
    /**
     * 选择单点
     */
    handleCheckedChange(value) {
      const checkCount = value.length;
      this.checkAll = checkCount === this.defaultCheckbox.length;
      this.isIndeterminate = checkCount > 0 && checkCount < this.defaultCheckbox.length;
      this.$nextTick(() => {
        this.emitCheckData();
      })
    },
    emitCheckData() {
      let checked = this.defaultCheckbox.filter(item => {
        let flag = false;
        this.checked.forEach(item1 => {
          if (item1 == item[this.defaultValueName]) {
            flag = true;
          }
        });
        return flag;
      });
      this.$emit('checked', {
        checkedValue: [...this.checked],
        checkedData: checked
      });
    },
    jsonTo(data) {
      return JSON.parse(JSON.stringify(data));
    }
  },
  watch: {
    valueName: {
      immediate: true,
      handler(name) {
        this.defaultValueName = name;
      },
    },
    labelName: {
      immediate: true,
      handler(name) {
        this.defaultLabelName = name;
      },
    },
    group: {
      immediate: true,
      handler(data) {
        data = this.jsonTo(data);
        this.checkCount = data.length;
        this.defaultCheckbox = data;
      },
    },
    checkedGroup: {
      immediate: true,
      // deep: true,
      handler(data) {
        // console.log(this.defaultCheckbox);
        // console.log(data);
        // console.log(this.defaultLabelName, this.defaultValueName);
        if (!data) {
          data = [];
        }
        let filterCheck = data.filter(item => {
          let flag = false;
          this.defaultCheckbox.forEach(item1 => {
            if (item === item1[this.defaultValueName]) {
              flag = true;
            }
          });
          return flag;
        });

        if (filterCheck.length === 0) {
          this.checkAll = false;
          this.isIndeterminate = false;
        } else if (this.defaultCheckbox.length === filterCheck.length) {
          this.checkAll = true;
          this.isIndeterminate = false;
        } else {
          this.checkAll = false;
          this.isIndeterminate = true;
        }

        this.checked = filterCheck;
      },
      deep: true
    },

  },
};
</script>

<style lang="scss">
.checkbox_week {
  display: flex;
  .el-checkbox {
    margin: 0 20px 0 0;
  }
}
</style>
