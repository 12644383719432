const tool = {
  /**
	 * 字符串补0，用于时间
	 * @param {number} n
	 * @returns {string}
	 */
  numAddZero(n) {
    return n > 9 ? n : '0' + n;
  },
  /**
	 * 格式化时间
	 * @param {string} time
	 * @returns {string}
	 */
  formatTime(time, status) {
    let date = {};
    let dateStr;
    time = new Date(time);
    date = {
      Y: time.getFullYear(),
      M: this.numAddZero(time.getMonth() + 1),
      D: this.numAddZero(time.getDate()),
      h: this.numAddZero(time.getHours()),
      m: this.numAddZero(time.getMinutes()),
      s: this.numAddZero(time.getSeconds()),
    };
    switch (status) {
      case 1:
        dateStr =  `${ date.Y }-${ date.M }-${ date.D } ${ date.h }:${ date.m }:${ date.s }`;
        break;
      case 2: {
        dateStr = `${ date.h }:${ date.m }:${ date.s }`;
        break;
      }
      default:
        dateStr =  `${ date.Y }-${ date.M }-${ date.D }`;
    }
    return dateStr;
  },
  formatDataStartEnd(date) {
    date = this.formatTime(date);
    return {
      startDate: date + ' 00:00:00',
      endDate: date + ' 23:59:59',
    };
  },
  // 删除HH:mm:ss 后面的秒
  removeSecond(str) {
    str = str.split(':');
    if (str.length === 3) {
      str.pop();
    }
    return str.join(':');
  },
  getYearWeek(dateString) {
    let  da = dateString;// 日期格式2015-12-30
    // 当前日期
    let  date1 = new Date(da.substring(0, 4), parseInt(da.substring(5, 7)) - 1, da.substring(8, 10));
    // 1月1号
    let  date2 = new Date(da.substring(0, 4), 0, 1);
    // 获取1月1号星期（以周一为第一天，0周一~6周日）
    let  dateWeekNum = date2.getDay() - 1;
    if (dateWeekNum < 0) {
      dateWeekNum = 6;
    }
    if (dateWeekNum < 4) {
      // 前移日期
      date2.setDate(date2.getDate() - dateWeekNum);
    } else {
      // 后移日期
      date2.setDate(date2.getDate() + 7 - dateWeekNum);
    }
    let  d = Math.round((date1.valueOf() - date2.valueOf()) / 86400000);
    if (d < 0) {
      let  date3 = date1.getFullYear() - 1 + '-12-31';
      return this.getYearWeek(date3);
    } else {
      // 得到年数周数
      let  year = date1.getFullYear();
      let  week = Math.ceil((d + 1) / 7);
      return {
        year,
        week
      };
    }
  }
};
export default tool;
